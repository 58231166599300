<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="Background List" no-body>
          <b-card-body>
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label class="mr-1">Show</label>
                <Select
                  @on-change="paginateChange"
                  v-model="search.paginate"
                  :clearable="false"
                >
                  <Option :value="10"> 10 </Option>
                  <Option :value="30"> 30 </Option>
                  <Option :value="50"> 50 </Option>
                  <Option :value="80"> 80 </Option>
                  <Option :value="100"> 100 </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="5">
                <Select
                  v-model="search.status"
                  @on-change="SearchData"
                  :clearable="true"
                  placeholder="Select Status"
                >
                  <Option :value="1"> Active </Option>
                  <Option :value="2"> Inactive </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="5">
                <Input
                  v-model="search.search_data"
                  class="d-inline-block mr-1"
                  placeholder="Search name..."
                  type="text"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Background</th>
                  <th>Name</th>
                  <th class="text-center">Status</th>
                  <th
                    v-if="permission.edit || permission.update"
                    class="text-center"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(certificate, index) in certificates.data"
                  :key="index"
                  v-if="certificates.data.length"
                >
                  <td class="align-middle text-center">
                    {{ certificates.meta.from + index }}
                  </td>
                  <td>
                    <img :src="certificate.file" width="150" alt="ff" />
                  </td>
                  <td>
                    <p>
                      <b>{{ certificate.name }}</b>
                    </p>
                    <p><b>Created by:</b> {{ certificate.created_by }}</p>
                  </td>

                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        certificate.status == 'Active'
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      {{ certificate.status }}
                    </span>
                  </td>

                  <td
                    class="align-middle text-center"
                    v-if="permission.edit || permission.update"
                  >
                    <button
                      v-if="permission.edit"
                      class="btn btn-primary btn-sm"
                      @click="Edit(certificate.id)"
                    >
                      <feather-icon icon="EditIcon" size="16" />
                    </button>
                    <button
                      v-if="permission.update"
                      class="btn btn-success btn-sm"
                      style="margin-left: 5px"
                      @click="statusUpdate(certificate.id)"
                    >
                      <feather-icon icon="CheckCircleIcon" size="16" />
                    </button>
                  </td>
                </tr>
                <tr v-if="!certificates.data.length" class="text-center">
                  <h3>No data found</h3>
                </tr>
              </tbody>
            </table>
          </div>
          <b-card-body>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-muted"
                >Showing {{ certificates.meta.from }} to
                {{ certificates.meta.to }} of
                {{ certificates.meta.total }} entries</span
              >
              <pagination
                :data="certificates"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
              ></pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != null">
        <b-card
          :title="'Certificate Background ' + (!form.id ? 'Add' : 'Update')"
        >
          <b-form @submit.prevent="!form.id ? Create() : Update(form.id)">
            <b-row class="justify-content-center">
              <b-col cols="12">
                <div class="form-group">
                  <label> Name </label>
                  <Input
                    v-model="form.name"
                    placeholder="Enter Certificate Name"
                    style="width: 100%"
                    type="text"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group">
                  <label> Background </label>

                  <div class="div p-2 border">
                    <img
                      :src="this.file ? this.file : getImage(160, 100)"
                      width="100%"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />

                    <div class="image mt-1">
                      <b-form-file
                        id="icon"
                        placeholder="Upload Icon..."
                        @change="loadImage($event)"
                      />
                    </div>
                  </div>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex justify-content-end align-items-center mt-2">
                  <div>
                    <b-button
                      variant="transparent"
                      class="border text-danger"
                      @click="Clear"
                    >
                      Clear
                    </b-button>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="form.busy"
                      class="ml-1"
                    >
                      {{ form.id ? "Update" : "Create" }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import { HasError } from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        file: null,
        _method: "POST",
      }),
      file: null,
      search: {
        search_data: "",
        status: "",
        paginate: 10,
        page: 1,
      },
      certificates: [],
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    BFormFile,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/certificate/background${queryParams}`)
        .then((res) => {
          this.certificates = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    loadImage(e) {
      this.form.file = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.file = "";
      this.file = "";
      this.form._method = "POST";
    },

    Create() {
      this.form
        .post("/app/certificate/background")
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Update(id) {
      this.form._method = "PUT";
      this.form
        .post("/app/certificate/background/" + id)
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Edit(id) {
      axios
        .get("app/certificate/background/" + id)
        .then((res) => {
          this.form.id = res.data.background.id;
          this.form.name = res.data.background.name;
          this.form.file = res.data.background.file;
          this.file = res.data.background.file
            ? this.$store.state.base_url + res.data.background.file
            : this.getImage(160, 70);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    statusUpdate(id) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, activate it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get("/app/certificate/background/status/" + id)
            .then((res) => {
              this.getResults();
              this.Clear();
              this.s(res.data.message);
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
        }
      });
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.background;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
